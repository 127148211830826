import React from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Card,
  Carousel,
} from "react-bootstrap";
import Line from "./Line";

const Listings = ({
  isLease = false,
  listings,
  keyword,
  setKeyword,
  bedFilter,
  setBedFilter,
  priceRangeFilter,
  setPriceRangeFilter,
  locationFilter,
  setLocationFilter,
}) => {
  return (
    <div id="listings-component" className="container">
      <h1 className="my-5">{isLease ? "Lease" : "Sales"}</h1>

      <Row className="mb-4">
        <Col md={6} className="mb-2 m-md-0">
          <InputGroup>
            <input
              placeholder="Keyword Search"
              aria-label="Keyword Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className="w-100"
            />
          </InputGroup>
        </Col>
        <Col md={2} className="my-2 m-md-0">
          <Form.Select
            aria-label="Bed"
            value={bedFilter}
            onChange={(e) => setBedFilter(e.target.value)}
          >
            <option value="">Bed</option>
            <option value="1">1 Bed</option>
            <option value="2">2 Beds</option>
            <option value="3">3 Beds</option>
          </Form.Select>
        </Col>
        <Col md={2} className="my-2 m-md-0">
          <Form.Select
            aria-label="Price Range"
            value={priceRangeFilter}
            onChange={(e) => setPriceRangeFilter(e.target.value)}
          >
            <option value="">Price Range</option>
            <option value="1">$0 - $500 PW</option>
            <option value="2">$500 - $1000 PW</option>
            <option value="3">$1000+ PW</option>
          </Form.Select>
        </Col>
        {/* <Col md={2} className="my-2 m-md-0">
          <Form.Select
            aria-label="Location"
            value={locationFilter}
            onChange={(e) => setLocationFilter(e.target.value)}
          >
            <option value="">Location</option>
            <option value="sydney">Sydney</option>
            <option value="melbourne">Melbourne</option>
            <option value="brisbane">Brisbane</option>
          </Form.Select>
        </Col> */}
      </Row>

      {!listings.length && (
        <>
          <p>No listings found</p>
          <Line />
        </>
      )}

      <Container>
        <Row className="g-5">
          {listings.map((listing) => (
            <Col md={4} key={listing.id} className="mb-4">
              <Card>
                <Carousel interval={null}>
                  {listing.images.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={image}
                        alt={`Slide ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <Card.Body>
                  <Card.Title>{listing.address}</Card.Title>
                  <Card.Text>
                    {listing.beds} BED — {listing.baths} BATH{" "}
                    {listing.cars > 0 ? `— ${listing.cars} CAR` : ""}
                  </Card.Text>
                  <Card.Text className="fw-bold">{listing.price}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Listings;
