import React, { useState, useEffect, useRef } from "react";
import CustomNavbar from "../components/Navbar";
import { Container } from "react-bootstrap";
import CustomFooter from "../components/Footer";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Home = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [playIntro, setPlayIntro] = useState(true);
  const [navbarBg, setNavbarBg] = useState("transparent");

  const showcaseSection = useRef(null);
  const landingVideoRef = useRef(null);

  const testimonials = [
    {
      name: "John F.",
      message:
        "Tim has been an incredible property manager! He’s always responsive and truly cares about his tenants. I couldn’t ask for a better experience.",
    },
    {
      name: "Samantha W.",
      message:
        "Tim's attention to detail and proactive approach make him stand out. He handles issues quickly and efficiently, ensuring everything runs smoothly.",
    },
    {
      name: "Alex T.",
      message:
        "I’ve worked with several property managers, but Tim is by far the best. His communication is excellent, and he always goes the extra mile for his clients.",
    },
    {
      name: "Emma R.",
      message:
        "Tim transformed my rental experience. He’s organised, professional, and always available to answer questions. Highly recommended!",
    },
    {
      name: "Liam K.",
      message:
        "Working with Tim has been a pleasure. His professionalism and dedication make him a great property manager. I highly recommend his services!",
    },
    {
      name: "Sophia M.",
      message:
        "From maintenance requests to lease renewals, Tim handles everything with ease. He’s friendly, knowledgeable, and truly makes a difference.",
    },
  ];

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 3 },
    desktop: { breakpoint: { max: 1024, min: 768 }, items: 2 },
    tablet: { breakpoint: { max: 768, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  useEffect(() => {
    if (location.state?.redirect) {
      setPlayIntro(false);
      setIsLoading(false);
    }
  }, [location.state]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setNavbarBg("#184a43");
      } else {
        setNavbarBg("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollTo = () => {
    window.scrollTo({
      top: showcaseSection.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleLoadingVideoEnd = () => {
    document.body.classList.remove("overflow-hidden");
    setIsLoading(false);
    if (landingVideoRef.current) {
      landingVideoRef.current.play();
    }
  };

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && playIntro && (
        <div>
          <video
            autoPlay
            muted
            playsInline
            preload="auto"
            onEnded={handleLoadingVideoEnd}
            className="vh-100 position-fixed w-100"
            style={{ objectFit: "cover", zIndex: 1000 }}
          >
            <source src="/assets/loading.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      <div id="home" style={{ visibility: isLoading ? "hidden" : "visible" }}>
        <div
          id="navbar-container"
          className="position-fixed w-100 navbar-bg bg-grey"
          style={{
            backgroundColor: navbarBg,
            transition: "background-color 0.3s ease",
            zIndex: 999,
          }}
        >
          <Container>
            <CustomNavbar />
          </Container>
        </div>

        {!isLoading && (
          <video
            ref={landingVideoRef}
            autoPlay
            muted
            playsInline
            loop
            preload="auto"
            className="position-absolute vh-100 vw-100"
            src="/assets/new.mp4"
            style={{ objectFit: "cover", zIndex: 1 }}
          >
            Your browser does not support the video tag.
          </video>
        )}

        <div
          id="landing"
          className="d-flex align-items-center justify-content-center vh-100 position-relative text-center"
        >
          <div style={{ zIndex: "999" }} className="px-3">
            <h1 className="text-white">EASTSIDE PROPERTY CO.</h1>
            <p className="text-white">
              DRIVEN BY EXCELLENCE. DEFINED BY SERVICE.
            </p>
            <button id="scroll-toggle" onClick={scrollTo}>
              <i className="fa-sharp fa-thin fa-arrow-down"></i>
            </button>
          </div>
        </div>

        {/* Testimonial Carousel Section */}
        <div className="my-5" ref={showcaseSection}>
          <Container>
            <h2 className="text-center mb-3">WHAT OUR CLIENTS SAY</h2>
            <Carousel responsive={responsive} infinite autoPlay swipeable>
              {testimonials.map((testimonial, index) => (
                <div key={index} className="p-4">
                  <h3 className="text-gold text-uppercase fs-5 mb-2">
                    {testimonial.name}
                  </h3>
                  <p className="text-black fw-light">{testimonial.message}</p>
                </div>
              ))}
            </Carousel>
          </Container>
        </div>

        <div className="position-relative">
          <img
            src="/assets/team/group.jpg"
            alt="Feature"
            className="feature-img w-100"
          />
          <div className="footer-blend"></div>
        </div>

        <CustomFooter />
      </div>
    </>
  );
};

export default Home;
