import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Leasing from "./pages/Leasing";
// import Sales from "./pages/Sales";
import Contact from "./pages/Contact";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/main.scss";
import "fullpage.js/dist/fullpage.min.css";
import "fullpage.js/dist/fullpage.min.js";
import "animate.css/animate.min.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/leasing/*" element={<Leasing />} />
        <Route path="/leasing/complete" element={<Leasing isComplete />} />
        <Route path="/leasing/all" element={<Leasing isAll />} />
        {/* <Route path="/sales" element={<Sales />} /> */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
