export const listings = [
  {
    id: 1,
    address: "8/10 Ocean Street North, Bondi NSW",
    type: "lease",
    beds: 2,
    baths: 1,
    cars: 1,
    price: "$950 PW",
    images: [
      "https://rimh2.domainstatic.com.au/3HWlwX89JcGFP1l0-Ghxh1XhU9g=/fit-in/1920x1080/filters:format(png):quality(80):no_upscale()/17097862_1_1_240721_055817-w5776-h4336",
      "https://rimh2.domainstatic.com.au/fVKt8YpC0tTMJ46wUjoWpA9vqxQ=/fit-in/1920x1080/filters:format(png):quality(80):no_upscale()/17097862_2_1_240721_055817-w5776-h4336",
    ],
    status: true,
  },
  {
    id: 2,
    address: "3 Benedictine Place, Cherrybrook NSW",
    type: "lease",
    beds: 5,
    baths: 3,
    cars: 4,
    price: "$1000 PW",
    images: [
      "https://i2.au.reastatic.net/800x600-format=webp/fe5ff46b98570331c302c04cb870e9dc508c21378ebffd2c20fbddc03a359f23/main.jpg",
      "https://i2.au.reastatic.net/800x600-format=webp/e164ee1816a571bf2a58560ff202b47ca2c2a33cb2221d3fc214fbde83ad9f37/image2.jpg",
      "https://i2.au.reastatic.net/800x600-format=webp/3a5547abe9ff5ac91211e8cc336dc9265b19d315f033a247d18d1bffb2417e6c/image3.jpg",
    ],

    status: true,
  },
  {
    id: 3,
    address: "23/112-134 Hall Street, Bondi Beach NSW",
    type: "lease",
    beds: 1,
    baths: 1,
    cars: 0,
    price: "$915 PW",
    images: [
      "https://i2.au.reastatic.net/1720x888-resize,extend,r=33,g=40,b=46/74524639cf34d32d38e741a16656db3e91de592d3c82a16f8ca11f4de2a773ac/main.jpg",
      "https://i2.au.reastatic.net/1720x888-resize,extend,r=33,g=40,b=46/c1d4e5b016457336bc160d85af9e638fbda1639791af30943931887c323af1bd/image2.jpg",
    ],
    status: true,
  },
  {
    id: 4,
    address: "41 Simmons Street, Enmore NSW",
    type: "lease",
    beds: 3,
    baths: 1,
    cars: 1,
    price: "$1000 PW",
    images: [
      "https://i2.au.reastatic.net/800x600-resize,extend,r=33,g=40,b=46/2cb2776959975940e47b04908b47101332abb2c4ebd5a8fccf12d1c9b5c16053/main.jpg",
      "https://i2.au.reastatic.net/800x600-resize,extend,r=33,g=40,b=46/6d11f084a05709402a3d428fd9a9e053b23389d392813422cfed35a88aa2d5bd/image2.jpg",
      "https://i2.au.reastatic.net/800x600-resize,extend,r=33,g=40,b=46/6e7975aec663e9792548b00a36544fa98e9ec15a8c5af0f3d1fa5bf54c6c7793/image3.jpg",
      "https://i2.au.reastatic.net/800x600-resize,extend,r=33,g=40,b=46/17f1a9d9faf39796053a5627db9411ab3fdb8d0081a2cc544558f4a3fb86f867/image4.jpg",
      "https://i2.au.reastatic.net/1000x500-resize,extend,r=33,g=40,b=46/a43da3202315a60a0decbb5711d6d240dd42d5a16c3ae93dcfa1e7c4a2809367/image5.jpg",
    ],
    status: true,
  },
  {
    id: 5,
    address: "6/28 Bondi Road, Bondi Junction NSW",
    type: "lease",
    beds: 1,
    baths: 2,
    cars: 1,
    price: "$950 PW",
    images: [
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/9976cc03734b7f1c6018e5c22503cf69d5025149eff977f596b1a9bec8df7197/image.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/f3321163bbfaf5be859a691e8a5db0fa31e6d2257e436265863c0922e3132a50/image.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/188bc8fd93c8e8c85e9906840ab186d0ee793324418e8794e32221a5786f7210/image.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/f11be50dfa448b0623c1bbb6dd6cc3023dc8b1998bd4bf654ae0db0d096a3677/image.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/92ea18b3d210a3c3402b65c6ec2e61c036dceda1e0325eb103956ac89f8aca31/image.jpg",
    ],
    status: true,
  },
  {
    id: 6,
    address: "2/313 Bondi Road, Bondi NSW",
    type: "lease",
    beds: 1,
    baths: 1,
    cars: 1,
    price: "$900 PW",
    images: [
      "https://rimh2.domainstatic.com.au/gq55VUnGBIXS4pDbpaqVWMtfCJU=/fit-in/1920x1080/filters:format(png):quality(80):no_upscale()/13280869_1_pi_190531_031311-w800-h533",
      "https://rimh2.domainstatic.com.au/6hYG1azsb13CNefkyv7FAeZw4hU=/fit-in/1920x1080/filters:format(png):quality(80):no_upscale()/13280869_2_pi_190531_031317-w800-h533",
      "https://rimh2.domainstatic.com.au/5Z4ydc8W1eMTYRNboDdWfgK2KdQ=/fit-in/1920x1080/filters:format(png):quality(80):no_upscale()/13280869_3_pi_190531_031324-w800-h533",
      "https://rimh2.domainstatic.com.au/cHOjWPvBFZivZiPs05cvkY5tH4I=/fit-in/1920x1080/filters:format(png):quality(80):no_upscale()/13280869_4_pi_190531_031333-w800-h533",
      "https://rimh2.domainstatic.com.au/Kj15qREMMqJtgOzESzPQ-44OlDI=/fit-in/1920x1080/filters:format(png):quality(80):no_upscale()/13280869_5_pi_190531_031338-w800-h533",
      "https://rimh2.domainstatic.com.au/9YnMIAJBcYUiXDiJyChsPit35UU=/fit-in/1920x1080/filters:format(png):quality(80):no_upscale()/13280869_6_pi_190531_031344-w800-h533",
    ],
    status: true,
  },
  {
    id: 7,
    address: "7/53-55B Frenchmans Road, Randwick NSW",
    type: "lease",
    beds: 1,
    baths: 1,
    cars: 1,
    price: "$750 PW",
    images: [
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/3c9011e24061af27962f639b2a08ac25dac820197893a0e24fe31c7e8a333f4b/main.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/c8a1bd8955bea76d7262a8fdd3eaebf515b8804abfaa432a2243cfde92eda760/image2.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/8694edcc0ebaaab89f8c9205df8d4d1633daf6ac01a901c8527e52de85eed433/image3.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/6b768abd71bed80a261e077dc19b95a705957073f2eabd8b06d1307d7f031712/image4.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/820e8a98ab2bb15ed8a0852f0bb21a1818947ea09c2d8b2379c19b635ec83e42/image5.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/2610ff4c03e1476b718f81b993cb8b3e9c4f077c888eccb6c4f95656202147ed/image6.jpg",
    ],
    status: true,
  },
  {
    id: 8,
    address: "7/112 Alison Road, Randwick NSW",
    type: "lease",
    beds: 3,
    baths: 1,
    cars: 1,
    price: "$1200 PW",
    images: [
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/cd484764cb03fe7ad4785c42cb1abcedb9669697993f469cf58c8ab159fd162c/main.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/69224371f6aa6fde49ab12bc56e31495c3750317ef66f1d5fd482ffcf5964f82/image2.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/12f8a733501a72f84b475e95e801602626fff153d198b346197c736dd22a9bd0/image3.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/6ddea4cd76cf489bef04296c10e486b51ef726a9797565642e386a78dd547635/image4.jpg",
      "https://i2.au.reastatic.net/1720x700-resize,extend,r=33,g=40,b=46/1600033b5b92f4de56d58b246dc2076a93a6fdcaf4eeb11e25bfb462353031d9/image5.jpg",
      "https://i2.au.reastatic.net/1720x600-resize,extend,r=33,g=40,b=46/206ea7146f24f7c407a3f3b2516000fc95ac74dfafbf2f53a486c98ee7d5810d/image6.jpg",
      "https://i2.au.reastatic.net/1720x600-resize,extend,r=33,g=40,b=46/4c86522dd57b5f13fde7310fe01539afa4fca5b00942edea975ca96b669bded7/image7.jpg",
    ],
    status: true,
  },
];
