import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Line from "./Line";

const CustomFooter = ({ dark = false }) => {
  return (
    <footer
      id="footer"
      className={`my-5 ${dark ? "bg-green text-white mb-0" : null}`}
    >
      <Container className={dark ? "py-3" : null}>
        <Row className="mb-md-5">
          <div className="d-flex align-items-center col-md-6 mb-3 mb-md-0">
            <img alt="Logo" height="100px" src="/assets/logo.png" />
            <div
              id="footer-pages"
              className="ms-4 h-100 d-flex flex-column justify-content-center"
            >
              <Link to="/" state={{ redirect: true }}>
                <i className="fa-light fa-dash"></i>HOME
              </Link>
              <Link to="/leasing" className="my-2">
                <i className="fa-light fa-dash"></i>LEASING
              </Link>
              {/* <Link to="/sales">
                <i className="fa-light fa-dash"></i>SALES
              </Link> */}
              <Link to="/contact">
                <i className="fa-light fa-dash"></i>CONTACT
              </Link>
            </div>
          </div>
          <div className="d-flex h-100 justify-content-between flex-column col-md-6 text-md-end">
            {/* 
            <form
              className={`d-flex position-relative w-100 ${
                dark ? "border-bot-white" : null
              }`}
            >
              <input placeholder="EMAIL ADDRESS" className="w-100" />
              <button className="position-absolute end-0">
                <i className="fa-light fa-arrow-right"></i>
              </button>
            </form> */}
            <p className="my-3 mt-md-0">
              Ready to experience excellence in real estate?
              <br />
              Reach out to us and let&apos;s begin your journey!
            </p>

            <div className="d-flex w-100 justify-content-md-end mt-2">
              <Link id="footer-contact" to="/contact">
                GET IN TOUCH
              </Link>
            </div>
          </div>
        </Row>
        <Line desktop />
        <div
          id="footer-links"
          className="d-md-flex align-items-center justify-content-between mt-4 mt-md-0"
        >
          <a
            id="nunu-link"
            href="https://nunu.pro"
            rel="noreferrer"
            target="_blank"
          >
            DEVELOPED BY&nbsp;<span>NUNU.PRO</span>
          </a>
          <div className="d-flex my-3 my-md-0">
            <a className="me-3">INSTAGRAM</a>
            <a>FACEBOOK</a>
          </div>
          <div className="d-flex">
            <a className="me-3">PRIVACY</a>
            <a>TERMS & CONDITIONS</a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default CustomFooter;
