import React from "react";

const Line = ({ desktop = false }) => {
  return (
    <hr
      className={desktop ? "d-none d-md-block" : null}
      style={{ border: "none", borderTop: "1px solid #ccc", margin: "20px 0" }}
    />
  );
};

export default Line;
