import React from "react";
import { Navbar, Nav, NavDropdown, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const CustomNavbar = ({ light = false }) => {
  const location = useLocation();

  const isLeasingActive =
    location.pathname === "/leasing/current" ||
    location.pathname === "/leasing/complete" ||
    location.pathname === "/leasing/all";

  return (
    <Navbar expand="lg" id="navbar" className={light ? "light" : null}>
      <Link
        to="/"
        state={{ redirect: true }}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Navbar.Brand className="d-flex align-items-center">
          <img alt="Logo" width="80px" src="/assets/logo.png" />
          <p className="d-md-block d-none ms-3 p-0 m-0 text-gold">
            EASTSIDE PROPERTY CO.
          </p>
        </Navbar.Brand>
      </Link>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto d-flex align-items-center">
          <NavDropdown
            title={
              <span className={light ? "text-grey" : "text-white"}>
                LEASING
              </span>
            }
            id="leasing-dropdown"
            className={`d-lg-block m-lg-0 custom-dropdown ${
              light ? "text-grey" : "text-white"
            } ${isLeasingActive ? "active" : ""}`}
          >
            <LinkContainer to="/leasing/current">
              <NavDropdown.Item>For Lease</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/leasing/complete">
              <NavDropdown.Item>Leased</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/leasing/all">
              <NavDropdown.Item>All Leasings</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

          <LinkContainer to="/contact">
            <NavItem className={`nav-link ${light ? "text-grey" : null}`}>
              CONTACT
            </NavItem>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
