import React, { useState } from "react";
import { Container } from "react-bootstrap";
import CustomNavbar from "../components/Navbar";
import CustomFooter from "../components/Footer";
import { listings } from "../data/Listings";
import Listings from "../components/Listings";

const Leasing = ({ isComplete = false, isAll = false }) => {
  const [keyword, setKeyword] = useState("");
  const [bedFilter, setBedFilter] = useState("");
  const [priceRangeFilter, setPriceRangeFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");

  // Filter Listings
  const leaseListings = listings
    .filter(
      (listing) =>
        listing.type === "lease" &&
        (isAll ? true : listing.status === isComplete)
    )
    .filter((listing) => {
      // Keyword filter
      if (
        keyword &&
        !listing.address.toLowerCase().includes(keyword.toLowerCase())
      ) {
        return false;
      }
      // Bed filter
      if (bedFilter && listing.beds.toString() !== bedFilter) {
        return false;
      }
      // Price range filter
      if (priceRangeFilter) {
        const price = parseInt(listing.price.replace(/[^0-9]/g, ""), 10); // Convert price to integer
        switch (priceRangeFilter) {
          case "1":
            if (price > 500) return false;
            break;
          case "2":
            if (price < 500 || price > 1000) return false;
            break;
          case "3":
            if (price < 1000) return false;
            break;
          default:
            break;
        }
      }
      // Location filter
      if (
        locationFilter &&
        !listing.address.toLowerCase().includes(locationFilter.toLowerCase())
      ) {
        return false;
      }
      return true;
    });

  return (
    <div id="leasing">
      <div className="bg-green">
        <Container>
          <CustomNavbar />
        </Container>
      </div>

      <Listings
        isLease
        listings={leaseListings}
        keyword={keyword}
        setKeyword={setKeyword}
        bedFilter={bedFilter}
        setBedFilter={setBedFilter}
        priceRangeFilter={priceRangeFilter}
        setPriceRangeFilter={setPriceRangeFilter}
        locationFilter={locationFilter}
        setLocationFilter={setLocationFilter}
      />

      <CustomFooter />
    </div>
  );
};

export default Leasing;
