import { Container, Row, Card, Col, Form } from "react-bootstrap";
import CustomNavbar from "../components/Navbar";
import CustomFooter from "../components/Footer";
import Line from "../components/Line";
import { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    console.log(publicKey);

    emailjs.sendForm(serviceId, templateId, e.target, publicKey).then(
      (result) => {
        console.log("Email sent successfully:", result.text);
        alert("Email sent successfully!");
        setName("");
        setEmail("");
        setMessage("");
      },
      (error) => {
        console.error("Error sending email:", error.text);
        alert("Failed to send email. Please try again later.");
      }
    );
  };

  return (
    <div id="contact">
      <div>
        <Container>
          <CustomNavbar light />
        </Container>
      </div>

      <div id="contact-team" className="mb-5 my-md-5 container-lg">
        <Row className="g-5">
          <Col>
            <Card>
              <img
                src="/assets/team/laurence.jpg"
                alt="Headshot"
                className="feature-img w-100"
              />
              <h2>LAURENCE SILTCHENKO</h2>
              <p>FOUNDER | DIRECTOR</p>
              <Line />
              <p className="text-black">+61 403 327 071</p>
              <a href="mailto:laurence@eastsidepropertyco.com">
                laurence@eastsidepropertyco.com
              </a>
            </Card>
          </Col>
          <Col>
            <Card>
              <img
                src="/assets/team/tim.jpg"
                alt="Headshot"
                className="feature-img w-100"
              />
              <h2>TIM TROYANOVSKY</h2>
              <p>L.R.E.A | HEAD OF PROPERTY MANAGEMENT | PARTNER</p>
              <Line />
              <p className="text-black">+61 477 005 097</p>
              <a href="mailto:tim@eastsidepropertyco.com">
                tim@eastsidepropertyco.com
              </a>
            </Card>
          </Col>
        </Row>
      </div>

      <Container id="contact-form" className="mb-5">
        <h1 className="text-center">GET IN TOUCH</h1>
        <Form onSubmit={sendEmail}>
          <Form.Group className="mb-3 d-flex flex-column" controlId="formName">
            <Form.Label>Name</Form.Label>
            <input
              type="text"
              name="user_name"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3 d-flex flex-column" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <input
              type="email"
              name="user_email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3 d-flex flex-column"
            controlId="formMessage"
          >
            <Form.Label>Message</Form.Label>
            <textarea
              name="message"
              rows={3}
              placeholder="Your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </Form.Group>

          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </Form>
      </Container>

      <div className="position-relative">
        <img
          src="/assets/team/group.jpg"
          alt="Feature"
          className="feature-img w-100"
        />
        <div className="footer-blend"></div>
      </div>

      <CustomFooter />
    </div>
  );
};

export default Contact;
